.container {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px #BABECC;
}
.container > span {
    color: black;
}
.label{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #7F8393;
    text-transform: capitalize;
}
.userMenuContainer {}
.userMenuContainer button {
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 16px!important;
    line-height: 24px!important;
    letter-spacing: 0.01em!important;
    color: #364658!important;
    position: relative;
    right: 30px;
}
.userMenuContainer  svg {
    font-size: 26px!important;
    position: relative;
    right: 25px;
    background: white;
    box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px #babecc;
    border-radius: 100px;
    padding: 9px;
}

.isProfile svg {
    color: white;
    background: linear-gradient(111.64deg, #009FFE 13.06%, #036FDC 48.22%, #908FFA 102.62%) !important;
}
.userMenuContainer .divider {
    margin: 20px;
}

.walletDescriptionBlock span{
    display: inline-block;
    width: 100%;
    text-align: center;
}
.walletDescriptionBlock .description {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #B0AFBB;
    margin-bottom: 18px;
}
.walletDescriptionBlock .total {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #7F8393;
    margin-bottom: 14px;
}
.walletDescriptionBlock .moneyCount {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
     color: #364658;
}