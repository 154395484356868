body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-styles-for-code-input {
  --ReactInputVerificationCode-itemWidth: 54px;
  --ReactInputVerificationCode-itemHeight: 52px;
}

.custom-styles-for-code-input .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}


.custom-styles-for-code-input .ReactInputVerificationCode__item.is-active { box-shadow: inset 0 0 3px  #009FFE; }
.cod-input-error .ReactInputVerificationCode__item{ box-shadow: inset 0 0 3px red; }

.code-input-label{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #364658;
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.custom-styles-for-code-input .color-error{ color: red; }

.input-password-color-good fieldset { border-color: #F8BF32!important; }
.input-password-color-good p { color: #F8BF32!important; }
.input-password-color-good .MuiInputLabel-root { color: #F8BF32!important; }

.input-password-color-high fieldset { border-color: #2BBA67!important;}
.input-password-color-high .MuiInputLabel-root { color: #2BBA67!important; }
.input-password-color-high p { color: #2BBA67!important; }

.MuiButton-text, .MuiButton-textPrimary { text-transform: none!important; }
.tabs-profile-customize button{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #7F8393;
  text-transform: capitalize!important;
}
.green-checkbox .Mui-checked {
  color: #FFFFFF!important;
}
.green-checkbox .MuiSwitch-track{

  height: 22px;
  border-radius: 11.6129px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: relative;
  top: -4px;
  opacity: 0.38!important;
}
.green-checkbox .Mui-checked+.MuiSwitch-track {
  background: #1CBD8B!important;
  opacity: 0.7!important;
}
.tabs-profile-customize .Mui-selected{ color: #364658!important }
.custom-dialog-border .MuiPaper-root { border-radius:  20px!important }
.custom-crop .reactEasyCrop_CropArea { box-shadow: white 0 0 0 9999em!important; }
.custom-menu-styles .MuiPaper-root {
  padding-top: 16px!important;
  padding-bottom: 16px!important;
  background: rgb(255, 255, 255) !important;
  box-shadow: rgb(255 255 255) -5px -5px 20px, rgb(186 190 204) 5px 5px 20px !important;
  border-radius: 8px!important;
}
